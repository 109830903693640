export const User = [
  {
    path: '/admin/user',
    name: 'user.user',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/user',
        name: 'user.list',
        component: () => import('./pages/List.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/user/add',
        name: 'user.add',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/user/edit/:id',
        name: 'user.edit',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
