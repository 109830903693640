export const locale = {
  Research_data: {
    Research_data: 'ข้อมูลงานวิจัย',
    list: 'รายการข้อมูลงานวิจัย',
    add: 'เพิ่ม',
    post_detail: 'รายละเอียดข้อมูลงานวิจัย',
    status: 'สถานะ',
    title: 'ชื่อข้อมูลงานวิจัย',
    body: 'รายละเอียดข้อมูลงานวิจัย',
    last_update: 'วันที่แก้ไข',
    picture_attachment: 'ภาพประกอบข้อมูลงานวิจัย',
    attachment: 'ไฟล์แนบ',
    category: 'หมวดหมู่',
    banner: 'Banner',
    publish: 'เผยแพร่',
    images: 'รูปแนบ',
  },
};
