import ApiService from '@/core/services/api.service';
const module_name = 'biodiversity_data';

export const GET_BATCH_LIST = module_name + '_GET_BATCH_LIST';
export const GET_BATCH_DATA = module_name + '_GET_BATCH_DATA';
export const SAVE_BATCH_DATA = module_name + '_SAVE_BATCH_DATA';
export const DELETE_BATCH_DATA = module_name + '_DELETE_BATCH_DATA';

//BIODIVERSITY
export const GET_BIODIVERSITY_LIST = module_name + '_GET_BIODIVERSITY_LIST';
export const GET_BIODIVERSITY_DATA = module_name + '_GET_BIODIVERSITY_DATA';
export const GET_EXPORT_BIODIVERSITY_DATA = module_name + '_GET_EXPORT_BIODIVERSITY_DATA';
export const SAVE_BIODIVERSITY_DATA = module_name + '_SAVE_BIODIVERSITY_DATA';
export const IMPORT_BIODIVERSITY_DATA = module_name + '_IMPORT_BIODIVERSITY_DATA';
export const DELETE_BIODIVERSITY_DATA = module_name + '_DELETE_BIODIVERSITY_DATA';
export const CLEAR_BIODIVERSITY_DATA = module_name + '_CLEAR_BIODIVERSITY_DATA';
export const GET_BIODIVERSITY_TEMPLATE_FILE = module_name + '_GET_BIODIVERSITY_TEMPLATE_FILE';
export const GET_PROVINCE_LIST = module_name + '_GET_PROVINCE_LIST';

//Station
export const GET_STATION_LIST = module_name + '_GET_STATION_LIST';
export const GET_STATION_DATA = module_name + '_GET_STATION_DATA';
export const SAVE_STATION_DATA = module_name + '_SAVE_STATION_DATA';
export const IMPORT_STATION_DATA = module_name + '_IMPORT_STATION_DATA';
export const DELETE_STATION = module_name + '_DELETE_STATION';
export const CLEAR_STATION_DATA = module_name + '_CLEAR_STATION_DATA';
export const GET_STATION_TEMPLATE_FILE = module_name + '_GET_STATION_TEMPLATE_FILE';

//Genus
export const GET_GENUS_LIST = module_name + '_GET_GENUS_LIST';
export const GET_GENUS_DATA = module_name + '_GET_GENUS_DATA';
export const SAVE_GENUS_DATA = module_name + '_SAVE_GENUS_DATA';
export const DELETE_GENUS = module_name + '_DELETE_GENUS';
export const CLEAR_GENUS_DATA = module_name + '_CLEAR_GENUS_DATA';
export const UPLOAD_GENUS_IMAGE = module_name + '_UPLOAD_GENUS_IMAGE';

const actions = {
  //================================================================================= Batch
  [GET_BATCH_LIST](context, credentials) {
    const URL = 'data_batch/biodiversity';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_BATCH_DATA](context, credentials) {
    const URL = 'data_batch/biodiversity';
    return new Promise((resolve, reject) => {
      ApiService.query(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [SAVE_BATCH_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'data_batch';
      // ApiService.setMultipartHeader();
      ApiService.post(URL, {batch_data: credentials})
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_BATCH_DATA](context, credentials) {
    const URL = 'data_batch';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL + '/' + credentials.id)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },

  //================================================================================= BIODIVERSITY
  [GET_BIODIVERSITY_LIST](context, credentials) {
    const URL = 'biodiversity';
    return new Promise((resolve, reject) => {
      ApiService.query(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_BIODIVERSITY_DATA](context, credentials) {
    const URL = 'biodiversity';
    return new Promise((resolve, reject) => {
      ApiService.query(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [SAVE_BIODIVERSITY_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'biodiversity';
      ApiService.post(URL, {biodiversity_data: credentials})
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [IMPORT_BIODIVERSITY_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'biodiversity/import';
      ApiService.setMultipartHeader();
      ApiService.post(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_BIODIVERSITY_DATA](context, credentials) {
    const URL = 'biodiversity';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL + '/' + credentials.id)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [CLEAR_BIODIVERSITY_DATA](context, credentials) {
    const URL = 'biodiversity/clear/' + credentials.id;
    return new Promise((resolve, reject) => {
      ApiService.delete(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_BIODIVERSITY_TEMPLATE_FILE](context, credentials) {
    const URL = 'biodiversity/template';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_EXPORT_BIODIVERSITY_DATA](context, credentials) {
    const URL = 'biodiversity/export/' + credentials;
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(res => {
          resolve(res);
        })
        .catch(response => {
          if (response === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Export failed. Please contact administrator.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.message);
          }
        });
    });
  },
  [GET_PROVINCE_LIST](context, credentials) {
    const URL = 'biodiversity/province';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.message);
          }
        });
    });
  },

  //================================================================================= Station
  [GET_STATION_LIST](context, credentials) {
    const URL = 'biodiversity_station';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_STATION_DATA](context, credentials) {
    const URL = 'biodiversity_station';
    return new Promise((resolve, reject) => {
      ApiService.query(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [SAVE_STATION_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'biodiversity_station';
      // ApiService.setMultipartHeader();
      ApiService.post(URL, {station_data: credentials})
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [IMPORT_STATION_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'biodiversity_station/import';
      ApiService.setMultipartHeader();
      ApiService.post(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_STATION](context, credentials) {
    const URL = 'biodiversity_station';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL + '/' + credentials.id)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [CLEAR_STATION_DATA](context, credentials) {
    const URL = 'biodiversity_station/clear';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_STATION_TEMPLATE_FILE](context, credentials) {
    const URL = 'biodiversity_station/station_template';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },

  //================================================================================= Genus
  [GET_GENUS_LIST](context, credentials) {
    const URL = 'biodiversity_genus';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_GENUS_DATA](context, credentials) {
    const URL = 'biodiversity_genus';
    return new Promise((resolve, reject) => {
      ApiService.query(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [SAVE_GENUS_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'biodiversity_genus';
      // ApiService.setMultipartHeader();
      ApiService.post(URL, {genus_data: credentials})
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_GENUS](context, credentials) {
    const URL = 'biodiversity_genus';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL + '/' + credentials.id)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [CLEAR_GENUS_DATA](context, credentials) {
    const URL = 'biodiversity_genus/clear';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [UPLOAD_GENUS_IMAGE](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setMultipartHeader();
      ApiService.post('attachment/upload', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
};

export default {
  // state,
  actions,
  // mutations,
  // getters,
};
