export const locale = {
  Contact_us: {
    Contact_us: 'ข้อมูลการติดต่อ',
    list: 'รายการ',
    name: 'ชื่อ - นามสกุล',
    subject: 'หัวข้อ',
    body: 'ข้อความ',
    last_update: 'วันที่เพิ่ม',
  },
};
