import e from '../utils/format';
export default function t() {
  return {
    validate(t) {
      const o =
        'select' === t.element.tagName.toLowerCase()
          ? t.element.querySelectorAll('option:checked').length
          : t.elements.filter((e) => e.checked).length;
      const s = t.options.min ? `${t.options.min}` : '';
      const n = t.options.max ? `${t.options.max}` : '';
      let a = t.l10n ? t.options.message || t.l10n.choice.default : t.options.message;
      const l = !((s && o < parseInt(s, 10)) || (n && o > parseInt(n, 10)));
      switch (true) {
        case !!s && !!n:
          a = e(t.l10n ? t.l10n.choice.between : t.options.message, [s, n]);
          break;
        case !!s:
          a = e(t.l10n ? t.l10n.choice.more : t.options.message, s);
          break;
        case !!n:
          a = e(t.l10n ? t.l10n.choice.less : t.options.message, n);
          break;
        default:
          break;
      }
      return {message: a, valid: l};
    },
  };
}
