export const locale = {
  News: {
    News: 'News',
    'News List': 'News',
    add: 'Add',
    status: 'Status',
    news_detail: 'News Details',
    title: 'Title',
    body: 'Body',
    last_update: 'Last Update',
    picture_attachment: 'Picture',
    attachment: 'Attachment',
    category: 'Category',
    banner: 'Banner',
    publish: 'Publish',
    images: 'Images',
  },
};
