export const locale = {
  Researcher: {
    Researcher: 'Researcher',
    list: 'Researcher',
    add: 'Add',
    post_detail: 'Details',
    status: 'Status',
    title: 'Name',
    role: 'Position',
    body: 'Desription',
    last_update: 'Last Update',
    banner: 'Picture',
    publish: 'Publish',
    nationality: 'Nationality',
    email: 'E-Mail',
    sorting: 'Sorting',
    row: 'Row',
    sort: 'Sort',
  },
};
