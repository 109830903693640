export const locale = {
  biodiversity: {
    publish: 'เผยแพร่',
    //batch
    batch_name: 'ชื่อชุดข้อมูล',
    batch_description: 'รายละเอียดชุดข้อมูล',
    batch_desc: 'คำอธิบาย',
    batch_data_list: 'รายการข้อมูล',

    //Station
    station_list: 'รายการสถานี',
    station_detail: 'รายละเอียดสถานี',
    station_code: 'รหัสสถานี',
    station_name: 'ชื่อสถานี',
    station_description: 'คำอธิบายสถานี',
    station_lat: 'Lon.',
    station_lon: 'Lat.',
    province: 'จังหวัด.',

    //Station
    list_genus: 'ข้อมูลจีนัส',
    genus_list: 'รายการจีนัส',
    genus_name: 'ชื่อ',
    genus_detail: 'รายละเอียดจีนัส',
    genus_slug: 'รหัสอ้างอิง',
    genus_description: 'เนื้อหา',

    image_attachment: 'รูปภาพ',
  },
};
