import ApiService from '@/core/services/api.service';

const module_name = 'organization_chart';

export const LIST = module_name + 'list';
export const QUERY = module_name + 'query';
export const SAVE_POST = module_name + 'save_post';
export const DELETE_POST = module_name + 'delete_post';
export const UPLOAD_FILE = module_name + 'upload_file';

const actions = {
  [LIST](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.get('organization_chart')
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [QUERY](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.query('organization_chart', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          // console.log(response);
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [SAVE_POST](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setMultipartHeader();
      ApiService.post('organization_chart', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_POST](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('organization_chart/delete', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [UPLOAD_FILE](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setMultipartHeader();
      ApiService.post('attachment/upload', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
};

export default {
  // state,
  actions,
  // mutations,
  // getters,
};
