import t from '../utils/format';
import e from '../utils/isValidDate';
export default function n() {
  const n = (t, e, n) => {
    const s = e.indexOf('YYYY');
    const a = e.indexOf('MM');
    const l = e.indexOf('DD');
    if (s === -1 || a === -1 || l === -1) {
      return null;
    }
    const o = t.split(' ');
    const r = o[0].split(n);
    if (r.length < 3) {
      return null;
    }
    const c = new Date(parseInt(r[s], 10), parseInt(r[a], 10) - 1, parseInt(r[l], 10));
    if (o.length > 1) {
      const t = o[1].split(':');
      c.setHours(t.length > 0 ? parseInt(t[0], 10) : 0);
      c.setMinutes(t.length > 1 ? parseInt(t[1], 10) : 0);
      c.setSeconds(t.length > 2 ? parseInt(t[2], 10) : 0);
    }
    return c;
  };
  const s = (t, e) => {
    const n = e
      .replace(/Y/g, 'y')
      .replace(/M/g, 'm')
      .replace(/D/g, 'd')
      .replace(/:m/g, ':M')
      .replace(/:mm/g, ':MM')
      .replace(/:S/, ':s')
      .replace(/:SS/, ':ss');
    const s = t.getDate();
    const a = s < 10 ? `0${s}` : s;
    const l = t.getMonth() + 1;
    const o = l < 10 ? `0${l}` : l;
    const r = `${t.getFullYear()}`.substr(2);
    const c = t.getFullYear();
    const i = t.getHours() % 12 || 12;
    const g = i < 10 ? `0${i}` : i;
    const u = t.getHours();
    const m = u < 10 ? `0${u}` : u;
    const d = t.getMinutes();
    const f = d < 10 ? `0${d}` : d;
    const p = t.getSeconds();
    const h = p < 10 ? `0${p}` : p;
    const $ = {
      H: `${u}`,
      HH: `${m}`,
      M: `${d}`,
      MM: `${f}`,
      d: `${s}`,
      dd: `${a}`,
      h: `${i}`,
      hh: `${g}`,
      m: `${l}`,
      mm: `${o}`,
      s: `${p}`,
      ss: `${h}`,
      yy: `${r}`,
      yyyy: `${c}`,
    };
    return n.replace(/d{1,4}|m{1,4}|yy(?:yy)?|([HhMs])\1?|"[^"]*"|'[^']*'/g, (t) =>
      $[t] ? $[t] : t.slice(1, t.length - 1),
    );
  };
  return {
    validate(a) {
      if (a.value === '') {
        return {meta: {date: null}, valid: true};
      }
      const l = Object.assign(
        {},
        {format: a.element && a.element.getAttribute('type') === 'date' ? 'YYYY-MM-DD' : 'MM/DD/YYYY', message: ''},
        a.options,
      );
      const o = a.l10n ? a.l10n.date.default : l.message;
      const r = {message: `${o}`, meta: {date: null}, valid: false};
      const c = l.format.split(' ');
      const i = c.length > 1 ? c[1] : null;
      const g = c.length > 2 ? c[2] : null;
      const u = a.value.split(' ');
      const m = u[0];
      const d = u.length > 1 ? u[1] : null;
      if (c.length !== u.length) {
        return r;
      }
      const f =
        l.separator || (m.indexOf('/') !== -1 ? '/' : m.indexOf('-') !== -1 ? '-' : m.indexOf('.') !== -1 ? '.' : '/');
      if (f === null || m.indexOf(f) === -1) {
        return r;
      }
      const p = m.split(f);
      const h = c[0].split(f);
      if (p.length !== h.length) {
        return r;
      }
      const $ = p[h.indexOf('YYYY')];
      const M = p[h.indexOf('MM')];
      const Y = p[h.indexOf('DD')];
      if (!/^\d+$/.test($) || !/^\d+$/.test(M) || !/^\d+$/.test(Y) || $.length > 4 || M.length > 2 || Y.length > 2) {
        return r;
      }
      const D = parseInt($, 10);
      const x = parseInt(M, 10);
      const y = parseInt(Y, 10);
      if (!e(D, x, y)) {
        return r;
      }
      const I = new Date(D, x - 1, y);
      if (i) {
        const t = d.split(':');
        if (i.split(':').length !== t.length) {
          return r;
        }
        const e = t.length > 0 ? (t[0].length <= 2 && /^\d+$/.test(t[0]) ? parseInt(t[0], 10) : -1) : 0;
        const n = t.length > 1 ? (t[1].length <= 2 && /^\d+$/.test(t[1]) ? parseInt(t[1], 10) : -1) : 0;
        const s = t.length > 2 ? (t[2].length <= 2 && /^\d+$/.test(t[2]) ? parseInt(t[2], 10) : -1) : 0;
        if (e === -1 || n === -1 || s === -1) {
          return r;
        }
        if (s < 0 || s > 60) {
          return r;
        }
        if (e < 0 || e >= 24 || (g && e > 12)) {
          return r;
        }
        if (n < 0 || n > 59) {
          return r;
        }
        I.setHours(e);
        I.setMinutes(n);
        I.setSeconds(s);
      }
      const O = typeof l.min === 'function' ? l.min() : l.min;
      const v = O instanceof Date ? O : O ? n(O, h, f) : I;
      const H = typeof l.max === 'function' ? l.max() : l.max;
      const T = H instanceof Date ? H : H ? n(H, h, f) : I;
      const S = O instanceof Date ? s(v, l.format) : O;
      const b = H instanceof Date ? s(T, l.format) : H;
      switch (true) {
        case !!S && !b:
          return {message: t(a.l10n ? a.l10n.date.min : o, S), meta: {date: I}, valid: I.getTime() >= v.getTime()};
        case !!b && !S:
          return {message: t(a.l10n ? a.l10n.date.max : o, b), meta: {date: I}, valid: I.getTime() <= T.getTime()};
        case !!b && !!S:
          return {
            message: t(a.l10n ? a.l10n.date.range : o, [S, b]),
            meta: {date: I},
            valid: I.getTime() <= T.getTime() && I.getTime() >= v.getTime(),
          };
        default:
          return {message: `${o}`, meta: {date: I}, valid: true};
      }
    },
  };
}
