export const AuthRoutes = [
  {
    path: '/',
    component: () => import('./AuthModule.vue'),
    children: [
      {
        name: 'login',
        path: '/login',
        component: () => import('./pages/Login.vue'),
        meta: {
          auth: false,
        },
      },
      {
        name: 'register',
        path: '/register',
        component: () => import('./pages/Login.vue'),
        meta: {
          auth: false,
        },
      },
      {
        name: 'logout',
        path: '/logout',
        component: () => import('./pages/Logout.vue'),
        meta: {
          auth: false,
        },
      },
    ],
  },
];
