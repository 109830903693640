export const locale = {
  Project_map: {
    Project_map: 'Project Map',
    list: 'Project',
    add: 'Add',
    post_detail: 'Project Details',
    status: 'Status',
    title: 'Title',
    body: 'Body',
    last_update: 'Last Update',
    publish: 'Publish',
    project_position: 'Positions',
    parent_project: 'Parent Project',
  },
};
