export const locale = {
  Contact_us: {
    Contact_us: 'Contact Us',
    list: 'Contact us list',
    name: 'Name',
    subject: 'Subject',
    body: 'Body',
    last_update: 'Added',
  },
};
