export const locale = {
  About_us: {
    About_us: 'About Us',
    contact_us: 'Contact Us',
    objective: 'Objective',
    list: 'About Us',
    add: 'Add',
    post_detail: 'About Us Details',
    status: 'Status',
    title: 'Title',
    body: 'Body',
    last_update: 'Last Update',
    picture_attachment: 'Picture',
    attachment: 'Attachment',
    category: 'Category',
    banner: 'Banner',
    publish: 'Publish',
    images: 'Images',
    parent_project: 'Main Project',
    researcher: 'Researcher',
  },
};
