export default function t(t) {
  let e = t.length;
  const l = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
  ];
  let n = 0;
  let r = 0;
  while (e--) {
    r += l[n][parseInt(t.charAt(e), 10)];
    n = 1 - n;
  }
  return r % 10 === 0 && r > 0;
}
