export const ProjectDescription = [
  {
    path: '/admin/project_description',
    name: 'project_description.project_description',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/project_description',
        name: 'project_description.list',
        component: () => import('./pages/List.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/project_description/add',
        name: 'project_description.add',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/project_description/edit/:id',
        name: 'project_description.edit',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
