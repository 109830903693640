export const locale = {
  waste: {
    publish: 'เผยแพร่',

    //batch
    batch_name: 'ชื่อชุดข้อมูล',
    batch_description: 'รายละเอียดชุดข้อมูล',
    batch_desc: 'คำอธิบาย',
    batch_data_list: 'รายการข้อมูล',

    //Station
    station_list: 'รายการสถานี',
    station_detail: 'รายละเอียดสถานี',
    station_code: 'รหัสสถานี',
    station_name: 'ชื่อสถานี',
    station_description: 'คำอธิบายสถานี',
    station_lat: 'Latitude',
    station_lon: 'Longitude',

    //Parameter
    parameter_list: 'รายการพารามิเตอร์',
    parameter_name: 'ชื่อพารามิเตอร์',
    parameter_unit: 'หน่วย',
  },
};
