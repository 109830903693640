export const locale = {
  Project_map: {
    Project_map: 'โครงการ',
    list: 'รายการโครงการ',
    add: 'เพิ่ม',
    post_detail: 'รายละเอียดโครงการ',
    status: 'สถานะ',
    title: 'ชื่อโครงการ',
    body: 'รายละเอียดโครงการ',
    last_update: 'วันที่แก้ไข',
    banner: 'Banner',
    publish: 'เผยแพร่',
    project_position: 'ตำแหน่งโครงการ',
    parent_project: 'โครงการหลัก',
  },
};
