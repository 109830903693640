import ApiService from '@/core/services/api.service';

export const GET_OPTION = 'get_option';

const actions = {
  [GET_OPTION](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.query('option', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
};

export default {
  actions,
};
