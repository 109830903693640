export const locale = {
  News: {
    News: 'ข่าวสาร',
    'News List': 'รายการข่าวสาร',
    add: 'เพิ่ม',
    status: 'สถานะ',
    news_detail: 'รายละเอียดข่าว',
    title: 'หัวข้อ',
    body: 'เนื้อหา',
    last_update: 'วันที่แก้ไข',
    picture_attachment: 'ภาพประกอบข่าว',
    attachment: 'ไฟล์แนบ',
    category: 'หมวดหมู่',
    banner: 'Banner',
    publish: 'เผยแพร่',
    images: 'รูปแนบ',
  },
};
