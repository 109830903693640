export const locale = {
  User: {
    User: 'ผู้ใช้งาน',
    list: 'ผู้ใช้งาน',
    user_detail: 'รายละเอียดผู้ใช้งาน',
    name: 'ชื่อ - นามสกุล',
    first_name: 'ชื่อ',
    last_name: 'นามสกุล',
    email: 'อีเมล์',
    username: 'ชื่อผู้ใช้งาน',
    password: 'พาสเวิร์ด',
    group: 'กลุ่ม',
    status: 'สถานะ',
    add: 'เพิ่ม',
    address: 'ที่อยู่',
    phone: 'โทรศัพท์',
    permission: 'สิทธิ์การใช้งาน',
  },
};
