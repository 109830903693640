export const ResearchData = [
  {
    path: '/admin/research_data',
    name: 'research_data.research_data',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/research_data',
        name: 'research_data.list',
        component: () => import('./pages/List.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/research_data/add',
        name: 'research_data.add',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/research_data/edit/:id',
        name: 'research_data.edit',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
