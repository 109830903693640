export const Salinity = [
  {
    path: '/admin/salinity',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/salinity/batch',
        name: 'salinity.list',
        component: () => import('./pages/Batch_list.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/salinity/batch/add',
        name: 'salinity.add_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/salinity/batch/add/:id',
        name: 'salinity.edit_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/salinity/station',
        name: 'salinity.list_station',
        component: () => import('./pages/Station_list.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/salinity/station/add',
        name: 'salinity.add_station',
        component: () => import('./pages/Station_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/salinity/station/add/:id',
        name: 'salinity.edit_station',
        component: () => import('./pages/Station_add.vue'),
        meta: {
          auth: true,
        },
      },

      //Parameter
      {
        path: '/admin/salinity/parameter',
        name: 'salinity.list_parameter',
        component: () => import('./pages/Parameter_list.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
