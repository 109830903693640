export const Biodiversity = [
  {
    path: '/admin/biodiversity',
    redirect: '/admin/biodiversity/batch',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/biodiversity/batch',
        name: 'biodiversity.list',
        component: () => import('./pages/Batch_list.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/biodiversity/batch/add',
        name: 'biodiversity.add_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/biodiversity/batch/:id',
        name: 'biodiversity.edit_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/biodiversity/station',
        name: 'biodiversity.list_station',
        component: () => import('./pages/Station_list.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/biodiversity/station/add',
        name: 'biodiversity.add_station',
        component: () => import('./pages/Station_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/biodiversity/station/add/:id',
        name: 'biodiversity.edit_station',
        component: () => import('./pages/Station_add.vue'),
        meta: {
          auth: true,
        },
      },

      //Genus
      {
        path: '/admin/biodiversity/genus',
        name: 'biodiversity.list_genus',
        component: () => import('./pages/Genus_list.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/biodiversity/genus/add',
        name: 'biodiversity.add_genus',
        component: () => import('./pages/Genus_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/biodiversity/genus/add/:id',
        name: 'biodiversity.edit_genus',
        component: () => import('./pages/Genus_add.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
