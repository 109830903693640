export const locale = {
  About_us: {
    About_us: 'เกี่ยวกับเรา',
    contact_us: 'ติดต่อเรา',
    objective: 'จุดประสงค์',
    list: 'รายการ',
    add: 'เพิ่ม',
    post_detail: 'รายละเอียดโครงการ',
    status: 'สถานะ',
    title: 'รายการ',
    body: 'รายละเอียดโครงการ',
    last_update: 'วันที่แก้ไข',
    picture_attachment: 'ภาพประกอบโครงการ',
    attachment: 'ไฟล์แนบ',
    category: 'หมวดหมู่',
    banner: 'Banner',
    publish: 'เผยแพร่',
    images: 'รูปแนบ',
    parent_project: 'โครงการหลัก',
    researcher: 'ผู้รับผิดชอบโครงการ',
  },
};
