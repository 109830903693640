export default {
  install(Vue, options) {
    Vue.directive('can', {
      inserted: function(el, binding, vnode) {
        let permissions = vnode.context.$store.getters.currentUser.user.permissions;
        let group = vnode.context.$store.getters.currentUser.user.group_name;
        if (!permissions.includes(binding.value) && !group.includes('admin')) {
          el.remove();
        }
      },
      update: function(el, binding, vnode) {
        let permissions = vnode.context.$store.getters.currentUser.user.permissions;
        let group = vnode.context.$store.getters.currentUser.user.group_name;
        if (!permissions.includes(binding.value) && !group.includes('admin')) {
          el.remove();
        }
      },
    });
  },
};
