export const locale = {
  ctd_data: {
    publish: 'Publish',
    //batch
    batch_name: 'Batch name',
    batch_description: 'Description',
    batch_desc: 'Description',
    batch_data_list: 'Batch List',

    //Station
    station_list: 'Station',
    station_detail: 'Detail',
    station_code: 'Code',
    station_name: 'Station Name',
    station_description: 'Description',
    station_lat: 'Latitude',
    station_lon: 'Longitude',

    //Parameter
    parameter_list: 'Parameter List',
    parameter_name: 'Name',
    parameter_unit: 'Unit',
  },
};
