export const locale = {
  News_category: {
    News_category: 'หมวดหมู่ข่าวสาร',
    list: 'รายการหมวดหมู่ข่าวสาร',
    add: 'เพิ่ม',
    post_detail: 'รายละเอียดหมวดหมู่ข่าวสาร',
    status: 'สถานะ',
    title: 'ชื่อหมวดหมู่ข่าวสาร',
    body: 'รายละเอียดหมวดหมู่ข่าวสาร',
    last_update: 'วันที่แก้ไข',
    picture_attachment: 'ภาพประกอบหมวดหมู่ข่าวสาร',
    attachment: 'ไฟล์แนบ',
    category: 'หมวดหมู่',
    banner: 'Banner',
    publish: 'เผยแพร่',
    images: 'รูป - ไฟล์แนบ',
    parent_project: 'หมวดหมู่ข่าวสารหลัก',
    researcher: 'ผู้รับผิดชอบหมวดหมู่ข่าวสาร',
  },
};
