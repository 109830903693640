export const GIS = [
  {
    path: '/admin/gis',
    redirect: '/admin/gis/batch',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/gis/batch',
        name: 'gis.list',
        component: () => import('./pages/Batch_list.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/gis/batch/add',
        name: 'gis.add_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/gis/batch/:id',
        name: 'gis.edit_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
