export const DashboardRoutes = [
  {
    path: '/admin/dashboard',
    redirect: '/admin/news',
    component: () => import('./DashboardModule.vue'),
    children: [
      {
        path: '/admin/dashboard',
        name: 'dashboard',
        redirect: '/admin/news',
        component: () => import('./pages/Dashboard.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
