export const NewsCategory = [
  {
    path: '/admin/news_category',
    name: 'news_category.news_category',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/news_category',
        name: 'news_category.list',
        component: () => import('./pages/List.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/news_category/add',
        name: 'news_category.add',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/news_category/edit/:id',
        name: 'news_category.edit',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
