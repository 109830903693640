import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/core/services/jwt.service';
import i18nService from '@/core/services/i18n.service.js';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = 'http://tcdic-api.localhost/v1';
    Vue.axios.defaults.baseURL = 'https://tcdic-api.phuketinnova.com/v1';
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common['Authorization'] = `${JwtService.getToken()}`;
  },
  /**
   * Set Request Language
   */
  setRequestLang() {
    // // console.log(i18nService.getActiveLanguage());
    //set Language
    Vue.axios.defaults.headers.common['Accept-Language'] = `${i18nService.getActiveLanguage()}`;
  },
  /**
   * Set the header for post data
   */
  setMultipartHeader() {
    Vue.axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
  },

  query(resource, params) {
    return Vue.axios.get(resource, {params: params}).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      // // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
