export const FrontpageRoutes = [
  {
    path: '/',
    component: () => import('./FrontpageModule.vue'),
    children: [
      {
        path: '/',
        name: 'frontpage',
        component: () => import('./pages/Frontpage.vue'),
        meta: {
          auth: false,
        },
      },
    ],
  },
];
