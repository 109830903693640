export default function e(e, t) {
  const n = (e) =>
    Object.keys(e)
      .map((t) => `${encodeURIComponent(t)}=${encodeURIComponent(e[t])}`)
      .join('&');
  return new Promise((o, s) => {
    const d = Object.assign({}, {crossDomain: false, headers: {}, method: 'GET', params: {}}, t);
    const a = Object.keys(d.params)
      .map((e) => `${encodeURIComponent(e)}=${encodeURIComponent(d.params[e])}`)
      .join('&');
    const r = e.indexOf('?');
    const c = 'GET' === d.method ? `${e}${r ? '?' : '&'}${a}` : e;
    if (d.crossDomain) {
      const e = document.createElement('script');
      const t = `___fetch${Date.now()}___`;
      window[t] = (e) => {
        delete window[t];
        o(e);
      };
      e.src = `${c}${r ? '&' : '?'}callback=${t}`;
      e.async = true;
      e.addEventListener('load', () => {
        e.parentNode.removeChild(e);
      });
      e.addEventListener('error', () => s);
      document.head.appendChild(e);
    } else {
      const e = new XMLHttpRequest();
      e.open(d.method, c);
      e.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      if ('POST' === d.method) {
        e.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      }
      Object.keys(d.headers).forEach((t) => e.setRequestHeader(t, d.headers[t]));
      e.addEventListener('load', function () {
        o(JSON.parse(this.responseText));
      });
      e.addEventListener('error', () => s);
      e.send(n(d.params));
    }
  });
}
