export const locale = {
  OrganizationChart: {
    OrganizationChart: 'Organization Chart',
    list: 'Row {0}',
    add: 'Add',
    post_detail: 'Details',
    status: 'Status',
    title: 'Name',
    role: 'Position',
    body: 'Desription',
    last_update: 'Last Update',
    banner: 'Picture',
    publish: 'Publish',
    sorting: 'Sorting',
    row: 'Row',
    sort: 'Sort',
  },
};
