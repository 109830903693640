const users = [
  {
    id: 1,
    name: 'Neo',
    about:
      "During the years prior to the events of Twn only as Morpheus. After an encounter wis suddenly contacted by Morpheus via a cell phone mailed to his office, but is almost immediately captured by the virtual reality's Agents, led by Agent Smith.",
  },
  {
    id: 2,
    name: 'Morpheus',
    about:
      'In the Matrix films, Morpheus ian city, Zion, in a devastated woept imprisoned in the Matrix, a virtual computer-generated world. Morpheus was once a human living inside the Matrix until he was freed.',
  },
  {
    id: 3,
    name: 'Trinity',
    about:
      'Trinity is a computer programmer and a hacker who has escaped from the Matrix,a sophisticated computer program in which most humans are imprisoned. Though few specifics are revealed about her previous life inside the Matrix,one of a number of real-world hovercraft commanders,',
  },
];

export class UserService {
  static getUser({id}) {
    return users.find(user => user.id === id);
  }
}
