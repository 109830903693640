import {locale as auth} from '@/modules/auth/i18n/th.js';
import {locale as NEWS} from '@/modules/news/i18n/th.js';
import {locale as Project_description} from '@/modules/project_description/i18n/th.js';
import {locale as Project_map} from '@/modules/project_map/i18n/th.js';
import {locale as OrganizationChart} from '@/modules/organization_chart/i18n/th.js';
import {locale as Researcher} from '@/modules/researcher/i18n/th.js';
import {locale as Research_data} from '@/modules/research_data/i18n/th.js';
import {locale as ContactUs} from '@/modules/contact_us/i18n/th.js';
import {locale as User} from '@/modules/user/i18n/th.js';
import {locale as About_us} from '@/modules/about_us/i18n/th.js';
import {locale as News_category} from '@/modules/news_category/i18n/th.js';
import {locale as CTD} from '@/modules/ctd_data/i18n/th.js';
import {locale as Temp} from '@/modules/timebase_temp/i18n/th.js';
import {locale as Salinity} from '@/modules/timebase_salinity/i18n/th.js';
import {locale as Waste} from '@/modules/station_waste/i18n/th.js';
import {locale as Waterquality} from '@/modules/station_waterquality/i18n/th.js';
import {locale as Sediment} from '@/modules/station_sediment/i18n/th.js';
import {locale as Biodiversity} from '@/modules/biodiversity/i18n/th.js';
import {locale as gis} from '@/modules/gis/i18n/th.js';
import {locale as gis_image} from '@/modules/gis_image/i18n/th.js';

export const locale = {
  ...auth,
  ...NEWS,
  ...Project_description,
  ...Project_map,
  ...OrganizationChart,
  ...Researcher,
  ...Research_data,
  ...ContactUs,
  ...About_us,
  ...User,
  ...News_category,
  ...CTD,
  ...Temp,
  ...Salinity,
  ...Waste,
  ...Waterquality,
  ...Sediment,
  ...Biodiversity,
  ...gis,
  ...gis_image,
  TRANSLATOR: {
    SELECT: 'เลือกภาษา',
  },
  MAIN_MENU: {
    news: 'ข่าวสาร',
    news_category: 'หมวดหมู่ข่าวสาร',
    project_description: 'โครงการ',
    project_map: 'แผนที่โครงการ',
    organization_chart: 'โครงสร้างองค์กร',
    researcher: 'รายชื่อนักวิจัย',
    about_us: 'เกี่ยวกับเรา',
    contact_us: 'ข้อมูลการติดต่อ',
    gis_data: 'ข้อมูลแผนที่ (GIS)',
    gis_image: 'ข้อมูลแผนที่ (Image Overlay)',
    ctd_data: 'ข้อมูล CTD',
    ctd_station: 'ตั้งค่าสถานี',
    ctd_paramenter: 'ตั้งค่าพารามิเตอร์',
    station_data: 'ข้อมูลประเภทสถานี',
    kmz_data: 'ข้อมูลประเภทไฟล์ภาพ',
    research_data: 'ข้อมูลงานวิจัย',
    timeseries_data: 'ข้อมูลสมุทรศาสตร์เชิงเวลา',
    temp: 'อุณหภูมิน้ำทะเล',
    temp_data: 'ข้อมูลอุณหภูมิน้ำทะเล',
    salinity: 'ข้อมูลความเค็มของน้ำทะเล',
    salinity_data: 'ข้อมูลความเค็ม',
    waste: 'ข้อมูลขยะ',
    waste_data: 'รายการข้อมูล',
    waterquality: 'ข้อมูลคุณภาพน้ำ',
    waterquality_data: 'รายการข้อมูล',
    sediment: 'ข้อมูลตะกอน',
    sediment_data: 'รายการข้อมูล',
    biodiversity: 'ความหลากหลายทางชีวภาพ',
    biodiversity_data: 'รายการข้อมูล',
    user_setting: 'ตั้งค่าผู้ใช้งาน',
    group_setting: 'ตั้งค่ากลุ่มผู้ใช้งาน',
  },
  GENERAL: {
    TOTAL_OF_LIST: 'ทั้งหมด {0} รายการ',
    option: 'ตัวเลือก',
    last_update: 'วันที่แก้ไขล่าสุด',
    add: 'เพิ่ม',
    attachment: 'ไฟล์แนบ',
  },
  MENU: {
    NEW: 'new',
    ACTIONS: 'Actions',
    CREATE_POST: 'Create New Post',
    PAGES: 'Pages',
    FEATURES: 'Features',
    APPS: 'Apps',
    DASHBOARD: 'กระดานหลัก',
  },
  AUTH: {
    GENERAL: {
      OR: 'Or',
      SUBMIT_BUTTON: 'Submit',
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: 'Sign Up',
      FORGOT_BUTTON: 'Forgot Password',
      BACK_BUTTON: 'Back',
      PRIVACY: 'Privacy',
      LEGAL: 'Legal',
      CONTACT: 'Contact',
    },
    LOGIN: {
      TITLE: 'Login Account',
      BUTTON: 'Sign In',
    },
    FORGOT: {
      TITLE: 'Forgot Password?',
      DESC: 'Enter your email to reset your password',
      SUCCESS: 'Your account has been successfully reset.',
    },
    REGISTER: {
      TITLE: 'Sign Up',
      DESC: 'Enter your details to create your account',
      SUCCESS: 'Your account has been successfuly registered.',
    },
    INPUT: {
      EMAIL: 'Email',
      FULLNAME: 'Fullname',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      USERNAME: 'Username',
    },
    VALIDATION: {
      INVALID: '{{name}} is not valid',
      REQUIRED: '{{name}} is required',
      MIN_LENGTH: '{{name}} minimum length is {{min}}',
      AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
      NOT_FOUND: 'The requested {{name}} is not found',
      INVALID_LOGIN: 'The login detail is incorrect',
      REQUIRED_FIELD: 'Required field',
      MIN_LENGTH_FIELD: 'Minimum field length:',
      MAX_LENGTH_FIELD: 'Maximum field length:',
      INVALID_FIELD: 'Field is not valid',
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: 'Selected records count: ',
      ALL: 'All',
      SUSPENDED: 'Suspended',
      ACTIVE: 'Active',
      FILTER: 'Filter',
      BY_STATUS: 'by Status',
      BY_TYPE: 'by Type',
      BUSINESS: 'Business',
      INDIVIDUAL: 'Individual',
      SEARCH: 'Search',
      IN_ALL_FIELDS: 'in all fields',
    },
    ECOMMERCE: 'eCommerce',
    CUSTOMERS: {
      CUSTOMERS: 'Customers',
      CUSTOMERS_LIST: 'Customers list',
      NEW_CUSTOMER: 'New Customer',
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: 'Customer Delete',
        DESCRIPTION: 'Are you sure to permanently delete this customer?',
        WAIT_DESCRIPTION: 'Customer is deleting...',
        MESSAGE: 'Customer has been deleted',
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: 'Customers Delete',
        DESCRIPTION: 'Are you sure to permanently delete selected customers?',
        WAIT_DESCRIPTION: 'Customers are deleting...',
        MESSAGE: 'Selected customers have been deleted',
      },
      UPDATE_STATUS: {
        TITLE: 'Status has been updated for selected customers',
        MESSAGE: 'Selected customers status have successfully been updated',
      },
      EDIT: {
        UPDATE_MESSAGE: 'Customer has been updated',
        ADD_MESSAGE: 'Customer has been created',
      },
    },
  },
};
