import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const UPDATE_PASSWORD = 'updateUser';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_PASSWORD = 'setPassword';
export const SET_ERROR = 'setError';

const state = {
  errors: null,
  user: {},
  permissions: [],
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  Permissions(state) {
    return state.permissions;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setRequestLang();
      ApiService.post('user/login', credentials)
        .then(({data}) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({response}) => {
          // console.log(response);
          if (response === undefined) {
            context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('login', credentials)
        .then(({data}) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({response}) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.setRequestLang();
        ApiService.get('user/verify')
          .then(({data}) => {
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(({response}) => {
            context.commit(PURGE_AUTH);
            reject(response);
          });
      } else {
        context.commit(PURGE_AUTH);
        reject();
      }
    });
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put('password', password).then(({data}) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.permissions = user.permissions;
    state.group = user.permissions;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.permissions = [];
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
