export const ContactUs = [
  {
    path: '/admin/contact_us',
    name: 'contact_us.contact_us',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/contact_us',
        name: 'contact_us.list',
        component: () => import('./pages/List.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
