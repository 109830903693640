export const Waste = [
  {
    path: '/admin/waste',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/waste/batch',
        name: 'waste.list',
        component: () => import('./pages/Batch_list.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/waste/batch/add',
        name: 'waste.add_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/waste/batch/add/:id',
        name: 'waste.edit_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/waste/station',
        name: 'waste.list_station',
        component: () => import('./pages/Station_list.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/waste/station/add',
        name: 'waste.add_station',
        component: () => import('./pages/Station_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/waste/station/add/:id',
        name: 'waste.edit_station',
        component: () => import('./pages/Station_add.vue'),
        meta: {
          auth: true,
        },
      },

      //Parameter
      {
        path: '/admin/waste/parameter',
        name: 'waste.list_parameter',
        component: () => import('./pages/Parameter_list.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
