export const locale = {
  Research_data: {
    Research_data: 'Research Data',
    list: 'Research Data',
    add: 'Add',
    post_detail: 'Research Data Details',
    status: 'Status',
    title: 'Title',
    body: 'Body',
    last_update: 'Last Update',
    picture_attachment: 'Picture',
    attachment: 'Attachment',
    category: 'Category',
    banner: 'Banner',
    publish: 'Publish',
    images: 'Images',
  },
};
