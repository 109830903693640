import ApiService from '@/core/services/api.service';
const module_name = 'research_data';
export const LIST = module_name + 'list';
export const SAVE_POST = module_name + 'save_post';
export const DELETE_POST = module_name + 'delete_post';
export const UPLOAD_FILE = module_name + 'upload_file';
export const UPLOAD_ATTACH = module_name + 'upload_attach';
export const DELETE_ATTACH = module_name + 'delete_attach';
export const QUERY = module_name + 'query';
export const GET_CAT = module_name + 'get_query';
export const ADD_CAT = module_name + 'add_query';

const actions = {
  [LIST](context) {
    return new Promise((resolve, reject) => {
      ApiService.get('research_data')
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          // console.log(response);
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_CAT](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.get('research_data_category')
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          // console.log(response);
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [QUERY](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.query('research_data', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          // console.log(response);
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [SAVE_POST](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setMultipartHeader();
      ApiService.post('research_data', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_POST](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('research_data/delete', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [UPLOAD_FILE](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setMultipartHeader();
      ApiService.post('attachment/upload', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [UPLOAD_ATTACH](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setMultipartHeader();
      ApiService.post('attachment/add', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_ATTACH](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('attachment/delete', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
};

export default {
  // state,
  actions,
  // mutations,
  // getters,
};
