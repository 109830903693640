export default function e() {
  return {
    validate(e) {
      if (e.value === '') {
        return {valid: true};
      }
      const t = e.options.regexp;
      if (t instanceof RegExp) {
        return {valid: t.test(e.value)};
      } else {
        const n = t.toString();
        const o = e.options.flags ? new RegExp(n, e.options.flags) : new RegExp(n);
        return {valid: o.test(e.value)};
      }
    },
  };
}
