export const Researcher = [
  {
    path: '/admin/researcher',
    name: 'researcher.researcher',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/researcher',
        name: 'researcher.list',
        component: () => import('./pages/List.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/researcher/add',
        name: 'researcher.add',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/researcher/edit/:id',
        name: 'researcher.edit',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
