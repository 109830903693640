export const locale = {
  gis_image: {
    publish: 'Publish',
    //batch
    batch_name: 'Batch name',
    batch_description: 'Description',
    batch_desc: 'Description',
    batch_data_list: 'List',

    layer_name: 'Layer name',
    layer_desc: 'Description',
    layer_file: 'File',

    south: 'South',
    west: 'West',
    north: 'North',
    east: 'East',
  },
};
