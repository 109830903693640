import ApiService from '@/core/services/api.service';
const module_name = 'ctd_data';

export const GET_BATCH_LIST = module_name + '_GET_BATCH_LIST';
export const GET_BATCH_DATA = module_name + '_GET_BATCH_DATA';
export const SAVE_BATCH_DATA = module_name + '_SAVE_BATCH_DATA';
export const DELETE_BATCH_DATA = module_name + '_DELETE_BATCH_DATA';

//CTD
export const GET_CTD_LIST = module_name + '_GET_CTD_LIST';
export const GET_CTD_DATA = module_name + '_GET_CTD_DATA';
export const GET_EXPORT_CTD_DATA = module_name + '_GET_EXPORT_CTD_DATA';
export const SAVE_CTD_DATA = module_name + '_SAVE_CTD_DATA';
export const IMPORT_CTD_DATA = module_name + '_IMPORT_CTD_DATA';
export const DELETE_CTD_DATA = module_name + '_DELETE_CTD_DATA';
export const CLEAR_CTD_DATA = module_name + '_CLEAR_CTD_DATA';
export const GET_CTD_TEMPLATE_FILE = module_name + '_GET_CTD_TEMPLATE_FILE';

//Station
export const GET_STATION_LIST = module_name + '_GET_STATION_LIST';
export const GET_STATION_DATA = module_name + '_GET_STATION_DATA';
export const SAVE_STATION_DATA = module_name + '_SAVE_STATION_DATA';
export const IMPORT_STATION_DATA = module_name + '_IMPORT_STATION_DATA';
export const DELETE_STATION = module_name + '_DELETE_STATION';
export const CLEAR_STATION_DATA = module_name + '_CLEAR_STATION_DATA';
export const GET_STATION_TEMPLATE_FILE = module_name + '_GET_STATION_TEMPLATE_FILE';

//Parameter
export const GET_PARAMETER_LIST = module_name + '_GET_PARAMETER_LIST';
export const GET_PARAMETER_DATA = module_name + '_GET_PARAMETER_DATA';
export const SAVE_PARAMETER_DATA = module_name + '_SAVE_PARAMETER_DATA';
export const DELETE_PARAMETER = module_name + '_DELETE_PARAMETER';

const actions = {
  //================================================================================= Batch
  [GET_BATCH_LIST](context, credentials) {
    const URL = 'data_batch/ctd';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_BATCH_DATA](context, credentials) {
    const URL = 'data_batch/ctd';
    return new Promise((resolve, reject) => {
      ApiService.query(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [SAVE_BATCH_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'data_batch';
      // ApiService.setMultipartHeader();
      ApiService.post(URL, {batch_data: credentials})
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_BATCH_DATA](context, credentials) {
    const URL = 'data_batch';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL + '/' + credentials.id)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },

  //================================================================================= CTD
  [GET_CTD_LIST](context, credentials) {
    const URL = 'ctd';
    return new Promise((resolve, reject) => {
      ApiService.query(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_CTD_DATA](context, credentials) {
    const URL = 'ctd';
    return new Promise((resolve, reject) => {
      ApiService.query(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [SAVE_CTD_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'ctd';
      ApiService.post(URL, {ctd_data: credentials})
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [IMPORT_CTD_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'ctd/import';
      ApiService.setMultipartHeader();
      ApiService.post(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_CTD_DATA](context, credentials) {
    const URL = 'ctd';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL + '/' + credentials.id)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [CLEAR_CTD_DATA](context, credentials) {
    const URL = 'ctd/clear/' + credentials.id;
    return new Promise((resolve, reject) => {
      ApiService.delete(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_CTD_TEMPLATE_FILE](context, credentials) {
    const URL = 'ctd/template';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_EXPORT_CTD_DATA](context, credentials) {
    const URL = 'ctd/export/' + credentials;
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(res => {
          resolve(res);
        })
        .catch(response => {
          if (response === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Export failed. Please contact administrator.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.message);
          }
        });
    });
  },

  //================================================================================= Station
  [GET_STATION_LIST](context, credentials) {
    const URL = 'ctd_station';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_STATION_DATA](context, credentials) {
    const URL = 'ctd_station';
    return new Promise((resolve, reject) => {
      ApiService.query(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [SAVE_STATION_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'ctd_station';
      // ApiService.setMultipartHeader();
      ApiService.post(URL, {station_data: credentials})
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [IMPORT_STATION_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'ctd_station/import';
      ApiService.setMultipartHeader();
      ApiService.post(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_STATION](context, credentials) {
    const URL = 'ctd_station';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL + '/' + credentials.id)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [CLEAR_STATION_DATA](context, credentials) {
    const URL = 'ctd_station/clear';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_STATION_TEMPLATE_FILE](context, credentials) {
    const URL = 'ctd_station/station_template';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },

  //================================================================================= Parameter
  [GET_PARAMETER_LIST](context, credentials) {
    const URL = 'parameter/ctd';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_PARAMETER_DATA](context, credentials) {
    const URL = 'parameter/ctd';
    return new Promise((resolve, reject) => {
      ApiService.query(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [SAVE_PARAMETER_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'parameter/ctd';
      ApiService.post(URL, {parameter_data: credentials})
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_PARAMETER](context, credentials) {
    const URL = 'parameter';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL + '/' + credentials.id)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response.data === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
};

export default {
  // state,
  actions,
  // mutations,
  // getters,
};
