export const NewsRoutes = [
  {
    path: '/admin',
    name: 'news.news',
    component: () => import('./NewsModule.vue'),
    children: [
      {
        path: '/admin/news',
        name: 'news.list',
        component: () => import('./pages/List.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/news/add',
        name: 'news.add',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/news/edit/:id',
        name: 'news.edit',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
