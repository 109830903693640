import Vue from 'vue';
import Router from 'vue-router';
import {ProfileRoutes} from '@/modules/profile/routes.js';
import {DashboardRoutes} from '@/modules/dashboard/routes.js';
import {AuthRoutes} from '@/modules/auth/routes.js';
import {FrontpageRoutes} from '@/modules/frontpage/routes.js';

import {User} from '@/modules/user/routes.js';
import {NewsRoutes} from '@/modules/news/routes.js';
import {ProjectDescription} from '@/modules/project_description/routes.js';
import {ProjectMap} from '@/modules/project_map/routes.js';
import {OrganizationChart} from '@/modules/organization_chart/routes.js';
import {Researcher} from '@/modules/researcher/routes.js';
import {ResearchData} from '@/modules/research_data/routes.js';
import {ContactUs} from '@/modules/contact_us/routes.js';
import {AboutUs} from '@/modules/about_us/routes.js';
import {NewsCategory} from '@/modules/news_category/routes.js';

import {CTDData} from '@/modules/ctd_data/routes.js';
import {Temp} from '@/modules/timebase_temp/routes.js';
import {Salinity} from '@/modules/timebase_salinity/routes.js';
import {Waste} from '@/modules/station_waste/routes.js';
import {Waterquality} from '@/modules/station_waterquality/routes.js';
import {Sediment} from '@/modules/station_sediment/routes.js';
import {Biodiversity} from '@/modules/biodiversity/routes.js';

import {GIS} from '@/modules/gis/routes.js';
import {GISImage} from '@/modules/gis_image/routes.js';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/admin/news',
      component: () => import('@/view/layout/front/Layout'),
      children: [...FrontpageRoutes],
    },
    {
      path: '/admin',
      redirect: '/admin/news',
      component: () => import('@/view/layout/admin/Layout'),
      children: [
        ...DashboardRoutes,
        ...ProfileRoutes,
        ...User,

        ...NewsRoutes,
        ...ProjectDescription,
        ...ProjectMap,
        ...OrganizationChart,
        ...Researcher,
        ...ResearchData,
        ...ContactUs,
        ...AboutUs,
        ...NewsCategory,

        ...CTDData,
        ...Temp,
        ...Salinity,
        ...Waste,
        ...Waterquality,
        ...Sediment,
        ...Biodiversity,

        ...GIS,
        ...GISImage,
      ],
    },
    ...AuthRoutes,
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue'),
    },
  ],
});
