export const locale = {
  OrganizationChart: {
    OrganizationChart: 'โครงสร้างองค์กร',
    list: 'แถวที่ {0}',
    add: 'เพิ่ม',
    post_detail: 'รายละเอียด',
    status: 'สถานะ',
    title: 'ชื่อ - นามสกุล',
    role: 'ตำแหน่ง',
    body: 'รายละเอียด',
    last_update: 'วันที่แก้ไข',
    banner: 'รูปภาพ',
    publish: 'เผยแพร่',
    sorting: 'การเรียงลำดับ',
    row: 'แถวที่',
    sort: 'ลำดับที่',
  },
};
