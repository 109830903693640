export const locale = {
  News_category: {
    News_category: 'News Category',
    list: 'News Category',
    add: 'Add',
    post_detail: 'News Category Details',
    status: 'Status',
    title: 'Title',
    body: 'Body',
    last_update: 'Last Update',
    picture_attachment: 'Picture',
    attachment: 'Attachment',
    category: 'Category',
    banner: 'Banner',
    publish: 'Publish',
    images: 'Images',
    parent_project: 'Main Project',
    researcher: 'Researcher',
  },
};
