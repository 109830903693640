export const locale = {
  biodiversity: {
    publish: 'Publish',

    //batch
    batch_name: 'Batch name',
    batch_description: 'Description',
    batch_desc: 'Description',
    batch_data_list: 'List',

    //Station
    station_list: 'Station',
    station_detail: 'Detail',
    station_code: 'Code',
    station_name: 'Station Name',
    station_description: 'Description',
    station_lat: 'Latitude',
    station_lon: 'Longitude',
    province: 'Province',

    //Station
    list_genus: 'Genus',
    genus_list: 'List',
    genus_name: 'Name',
    genus_detail: 'Description',
    genus_slug: 'slug',
    genus_description: 'Description',

    image_attachment: 'Image',
  },
};
