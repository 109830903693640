export const CTDData = [
  {
    path: '/admin/ctd',
    redirect: '/admin/ctd/batch',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/ctd/batch',
        name: 'ctd_data.list',
        component: () => import('./pages/Batch_list.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/ctd/batch/add',
        name: 'ctd_data.add_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/ctd/batch/:id',
        name: 'ctd_data.edit_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/ctd/station',
        name: 'ctd_data.list_station',
        component: () => import('./pages/Station_list.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/ctd/station/add',
        name: 'ctd_data.add_station',
        component: () => import('./pages/Station_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/ctd/station/add/:id',
        name: 'ctd_data.edit_station',
        component: () => import('./pages/Station_add.vue'),
        meta: {
          auth: true,
        },
      },

      //Parameter
      {
        path: '/admin/ctd/parameter',
        name: 'ctd_data.list_parameter',
        component: () => import('./pages/Parameter_list.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
