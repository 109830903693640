export const AboutUs = [
  {
    path: '/admin/about_us',
    name: 'about_us.about_us',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/about_us',
        name: 'about_us.list',
        component: () => import('./pages/List.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/about_us/add',
        name: 'about_us.add',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/about_us/edit/:id',
        name: 'about_us.edit',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
