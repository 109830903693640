import ApiService from '@/core/services/api.service';
const module_name = 'gis_image';

export const GET_BATCH_LIST = module_name + '_GET_BATCH_LIST';
export const GET_BATCH_DATA = module_name + '_GET_BATCH_DATA';
export const SAVE_BATCH_DATA = module_name + '_SAVE_BATCH_DATA';
export const DELETE_BATCH_DATA = module_name + '_DELETE_BATCH_DATA';
export const UPLOAD_IMAGE = module_name + '_UPLOAD_IMAGE';

const actions = {
  //================================================================================= Batch
  [GET_BATCH_LIST](context, credentials) {
    const URL = 'gis_image';
    return new Promise((resolve, reject) => {
      ApiService.get(URL)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [GET_BATCH_DATA](context, credentials) {
    const URL = 'gis_image';
    return new Promise((resolve, reject) => {
      ApiService.query(URL, credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [SAVE_BATCH_DATA](context, credentials) {
    return new Promise((resolve, reject) => {
      const URL = 'gis_image';
      // ApiService.setMultipartHeader();
      ApiService.post(URL, {batch_data: credentials})
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
  [DELETE_BATCH_DATA](context, credentials) {
    const URL = 'gis_image';
    return new Promise((resolve, reject) => {
      ApiService.delete(URL + '/' + credentials.id)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          if (response === undefined) {
            // context.commit(SET_ERROR, `Can't connect to server.`);
            reject(`Can't connect to server.`);
          } else {
            // context.commit(SET_ERROR, response.data.message);
            reject(response.data.message);
          }
        });
    });
  },
  [UPLOAD_IMAGE](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setMultipartHeader();
      ApiService.post('attachment/upload', credentials)
        .then(({data}) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.errors);
        });
    });
  },
};

export default {
  // state,
  actions,
  // mutations,
  // getters,
};
