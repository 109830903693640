// action types
export const UPDATE_PERSONAL_INFO = 'updateUserInfo';
export const UPDATE_ACCOUNT_INFO = 'updateUserAccountInfo';

// mutation types
export const SET_PERSONAL_INFO = 'setPersonalInfo';
export const SET_ACCOUNT_INFO = 'setAccountInfo';

const state = {
  user_info: {},
};

const getters = {
  currentUserInfo(state) {
    return state.user_info;
  },
  currentUserPhoto(state) {
    return state.user_info.photo;
  },
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    context.commit(SET_PERSONAL_INFO, payload);
  },
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_info = user_personal_info;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
