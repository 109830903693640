export const locale = {
  User: {
    User: 'User',
    list: 'User list',
    user_detail: 'User detail',
    name: 'Firstname - Lastname',
    first_name: 'First name',
    last_name: 'Last name',
    email: 'E-Mail',
    username: 'Username',
    password: 'Password',
    group: 'Group',
    status: 'Status',
    add: 'Add',
    address: 'Address',
    phone: 'Phone',
    permission: 'Permission',
  },
};
