import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';
import profile from './profile.module';
import option from './option.module';
import attachment from './attachment.module';

import news from '@/modules/news/services.js';
import project_description from '@/modules/project_description/services.js';
import project_map from '@/modules/project_map/services.js';
import organization_chart from '@/modules/organization_chart/services.js';
import researcher from '@/modules/researcher/services.js';
import research_data from '@/modules/research_data/services.js';
import contact_us from '@/modules/contact_us/services.js';
import user from '@/modules/user/services.js';
import about_us from '@/modules/about_us/services.js';
import news_category from '@/modules/news_category/services.js';

import ctd_data from '@/modules/ctd_data/services.js';
import timebase_temp from '@/modules/timebase_temp/services.js';
import timebase_salinity from '@/modules/timebase_salinity/services.js';
import station_waste from '@/modules/station_waste/services.js';
import station_waterquality from '@/modules/station_waterquality/services.js';
import station_sediment from '@/modules/station_sediment/services.js';
import biodiversity from '@/modules/biodiversity/services.js';

import gis from '@/modules/gis/services.js';
import gis_image from '@/modules/gis_image/services.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    option,
    attachment,

    news,
    project_description,
    project_map,
    organization_chart,
    researcher,
    research_data,
    contact_us,
    user,
    about_us,
    news_category,

    ctd_data,
    timebase_temp,
    timebase_salinity,
    station_waste,
    station_waterquality,
    station_sediment,
    biodiversity,

    gis,
    gis_image,
  },
});
