import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import ApiService from '@/core/services/api.service';
// import MockService from '@/core/mock/mock.service';
import {VERIFY_AUTH} from '@/core/services/store/auth.module';
import {RESET_LAYOUT_CONFIG} from '@/core/services/store/config.module';
import {UPDATE_PERSONAL_INFO} from '@/core/services/store/profile.module';

Vue.config.productionTip = false;

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
import vuetify from '@/core/plugins/vuetify';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/apexcharts';
import '@/core/plugins/treeselect';
import '@/core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';
import '@/core/plugins/formvalidation';
import '@/core/plugins/ckeditor';
import '@/core/plugins/vue-clipboard2';

import Permission from '@/core/directives/permission';
import Mixins from '@/core/mixins/util';

// API service init
ApiService.init();

// Custom Directive
Vue.use(Permission);
Vue.use(Mixins);

// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    // Ensure we checked auth before each page load.
    Promise.all([store.dispatch(VERIFY_AUTH)])
      .then(data => {
        //Accepted
        store.dispatch(UPDATE_PERSONAL_INFO, data[0].user);
      })
      .catch(() => {
        if (to.name !== 'login' && from.name !== 'login') {
          router.push({name: 'login'});
        }
      })
      .then(() => {
        next();
      });
  } else {
    next();
  }
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.filter('str_limit', function(value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});
Vue.filter('stripHTML', function(value) {
  const div = document.createElement('div');
  div.innerHTML = value;
  const text = div.textContent || div.innerText || '';
  return text;
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app');
