export const locale = {
  Researcher: {
    Researcher: 'นักวิจัย',
    list: 'รายชื่อนักวิจัย',
    add: 'เพิ่ม',
    post_detail: 'รายละเอียด',
    status: 'สถานะ',
    title: 'ชื่อ - นามสกุล',
    role: 'ตำแหน่ง',
    body: 'รายละเอียด',
    last_update: 'วันที่แก้ไข',
    banner: 'รูปภาพ',
    publish: 'เผยแพร่',
    nationality: 'สัญชาติ',
    email: 'E-Mail',
    sorting: 'การเรียงลำดับ',
    row: 'แถวที่',
    sort: 'ลำดับที่',
  },
};
