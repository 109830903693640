export const locale = {
  gis: {
    publish: 'เผยแพร่',
    //batch
    batch_name: 'ชื่อชุดข้อมูล',
    batch_description: 'รายละเอียดชุดข้อมูล',
    batch_desc: 'คำอธิบาย',
    batch_data_list: 'รายการข้อมูล',

    layer_name: 'ชื่อชั้นข้อมูล',
    layer_desc: 'รายละเอียด',
    layer_file: 'ไฟล์ข้อมูล',
  },
};
