export const GISImage = [
  {
    path: '/admin/gis_image',
    redirect: '/admin/gis_image/batch',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/gis_image/batch',
        name: 'gis_image.list',
        component: () => import('./pages/Batch_list.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/gis_image/batch/add',
        name: 'gis_image.add_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/gis_image/batch/:id',
        name: 'gis_image.edit_batch',
        component: () => import('./pages/Batch_add.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
