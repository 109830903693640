export const locale = {
  Project_description: {
    Project_description: 'โครงการ',
    list: 'รายการโครงการ',
    add: 'เพิ่ม',
    post_detail: 'รายละเอียดโครงการ',
    status: 'สถานะ',
    title: 'ชื่อโครงการ',
    body: 'รายละเอียดโครงการ',
    last_update: 'วันที่แก้ไข',
    picture_attachment: 'ภาพประกอบโครงการ',
    attachment: 'ไฟล์แนบ',
    category: 'หมวดหมู่',
    banner: 'Banner',
    publish: 'เผยแพร่',
    images: 'รูป - ไฟล์แนบ',
    parent_project: 'โครงการหลัก',
    researcher: 'ผู้รับผิดชอบโครงการ',
  },
};
