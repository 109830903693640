export const OrganizationChart = [
  {
    path: '/admin/organization_chart',
    name: 'organization_chart.organization_chart',
    component: () => import('./Module.vue'),
    children: [
      {
        path: '/admin/organization_chart',
        name: 'organization_chart.list',
        component: () => import('./pages/List.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/organization_chart/add/:row',
        name: 'organization_chart.add',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/admin/organization_chart/edit/:id',
        name: 'organization_chart.edit',
        component: () => import('./pages/Add.vue'),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
