import ApiService from '@/core/services/api.service';

export const UPLOAD_ATTACH = 'UPLOAD_ATTACH';
export const DELETE_ATTACH = 'DELETE_ATTACH';

export default {
  state: {},
  getters: {},
  actions: {
    [UPLOAD_ATTACH](context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.setMultipartHeader();
        ApiService.post('attachment/add', credentials)
          .then(({data}) => {
            resolve(data);
          })
          .catch(({response}) => {
            reject(response.data.errors);
          });
      });
    },
    [DELETE_ATTACH](context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post('attachment/delete', credentials)
          .then(({data}) => {
            resolve(data);
          })
          .catch(({response}) => {
            reject(response.data.errors);
          });
      });
    },
  },
  mutations: {},
};
